import {useEffect} from "react";
import { withRouter} from "react-router-dom";

const ScrollToTop = ({ children, location: { pathname,hash } }:any) => {
    useEffect(() => {
      
      if(hash === "")
      {
        window.scrollTo(0, 0);
      }
    }, [pathname]);
  
    return children;
  };
  
export default withRouter(ScrollToTop);

