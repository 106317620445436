import React from 'react'
import { Map, Marker, TileLayer } from 'react-leaflet'

const position: [number, number] = [59.2988663, 18.0146312]
export class YmMap extends React.Component {

  public render() {
    return <div style={{ height: '50vh' }} className="w-100">
      <Map center={position} zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <Marker position={position}>
        </Marker>
      </Map>
    </div>
  }
}

