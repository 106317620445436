import React from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { YmModal } from '../components/YmModal/YmModal';
import { HubSpotService } from '../services/hubSpotService';
import Loader from '../components/Loader';

export interface NewsLetterFormState {
    data: any;
    fieldErrors: any;
    isModalOpen:boolean;
    error:boolean;
    isLoading:boolean;
    
}

export interface NewsLetterFormProps {}

const formFields = [
    {
        type: "Text",
        name: "email",
        placeholder: "Add your email address",
        required: true,
        errorMessage: "Mandatory field"
    },
]
export class NewsLetterForm extends React.Component<NewsLetterFormProps,NewsLetterFormState> {

    private _hubspotService:HubSpotService;
    constructor(props: NewsLetterFormProps) {
        super(props);
        this.state = {
            data: {email:''},
            fieldErrors: {},
            isModalOpen:false,
            error:false,
            isLoading:false

        }
        this._hubspotService = new HubSpotService();
    }

    public componentDidMount() {
        let data: any = {};
        formFields.forEach((field) => {
            data[field.name] = ''
        });
        this.setState({ data: data });
    }


    private onValueChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
        let fieldName = event.target.name;
        let newValue = event.target.value;

        const currentField = formFields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        this.setState((prevState, props) => {
            return {
                ...prevState,
                data: { ...prevState.data, [fieldName]: newValue },
                fieldErrors: {
                    ...prevState.fieldErrors, [fieldName]: {
                        inValid: (currentField.required) && !newValue ? true : false,
                        message: 'Field is required'
                    }
                }
            }
        });
    }

    private onSubmit = () => {
        let { data } = this.state;
        if (this.isFormValid()) {
            this.setState({error:false, isLoading:true});
            this._hubspotService.signUpToNewsLetter(data.email).then((response) => {
                this.setState({data:{email:''},isModalOpen:true,isLoading:false});
            }).catch((ex) => {
                this.setState({error:true,isLoading:false});
            });
        }
    }

    private isFormValid = () => {
        let { data } = this.state;
        let isValid = false;
        let requiredFields = formFields.filter((field) => field.required);

        let emptyFileds: any = {};
        requiredFields.filter((field) => data[field.name] === '' || data[field.name] === null).map((field) => emptyFileds[field.name] = { inValid: true, message: 'Field is required' });

        this.setState((prevState) => {
            return {
                ...prevState,
                fieldErrors: emptyFileds
            };
        },
        );

        isValid = !Object.keys(emptyFileds).length;
        if (!isValid) {
            return isValid;
        }

        //Format Validations
        let wrongFormat: any = {};
        var emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?w+)*(.\w{2,3})+$/;
        if (data.email) {
            if (!data.email.match(emailFormat)) {
                wrongFormat.email = { inValid: true, message: 'Wrong email format' };
                isValid = false
            }
        }
        this.setState((prevState) => {
            return {
                ...prevState,
                fieldErrors: wrongFormat
            };
        },
        );

        return isValid;
    }

    public render(){
        return (
            <Row>
            {
                 formFields.map((field, index) => {
                    return <Col key={`formfield_${index}`} xs="12" sm="6" md="6" lg="8" className="mt-4 pr-3">
                        <Input
                            style={{ height: '40px', borderRadius:'10rem', border:'none' }}
                            name={field.name}
                            value={this.state.data[field.name] ? this.state.data[field.name] : '' }
                            placeholder={field.placeholder}
                            className="px-4" type="text"
                            onChange={this.onValueChanged}
                            invalid={this.state.fieldErrors[field.name] && this.state.fieldErrors[field.name].inValid}
                        ></Input>
                        {
                            this.state.error && 
                            <div style={{fontSize:'16px'}} className="text-danger px-4 pt-1">Something went wrong, try again later!</div>
                        }
                    </Col>
                })
            }
            <Col sm="6" md="6" lg="4" className="px-md-0 mt-4"><Button size="lg" onClick={this.onSubmit} color={"primary"} style={{borderRadius: '10rem', height:'40px'}} className="btn-pill px-4 py-1">Subscribe</Button></Col>
            <YmModal
                isOpen={this.state.isModalOpen}
                title={'Thank you!'}
                text="Thank you for signing up to our newletter."
                toggleModal={() => this.setState({isModalOpen:!this.state.isModalOpen})}
                ></YmModal>
             <Loader isOpen={this.state.isLoading}></Loader>   
        </Row>
        )
    }
}