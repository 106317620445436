import React, { ReactNode } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

export const YmModal: React.FC<{ title: string, text: ReactNode, isOpen: boolean, toggleModal: any }> = (props) => {
    return (
        <Modal size="md" centered={true} isOpen={props.isOpen} toggle={props.toggleModal}>
            <ModalHeader tag={'h3'} toggle={props.toggleModal}>
                <span className={'text-primary'}>{props.title}</span>
            </ModalHeader>
            <ModalBody className="m-3">
                {props.text}
            </ModalBody>
            <ModalFooter>
                <Button
                    color={"primary"}
                    onClick={props.toggleModal}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}