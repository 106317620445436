import React, { useState } from 'react';
import {Carousel,CarouselItem,CarouselControl,Button} from 'reactstrap';
import { YmCarouselProps } from './YmCarouselProps';
import {Link} from 'react-router-dom';

export const YmCarousel: React.FunctionComponent<YmCarouselProps> = (props) => {

    const {items} = props;

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
  
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }

    const slides = items.map((item,index) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            tag="div"
            key={`carousel_item_${index}`}
          >
         
            <div className={'cover-scroll'} style={{ height: '75vh', width: '100%', backgroundImage: `url('${item.src}')` }}></div>
            <div className="carousel-content text-center">
            <h1 className="text-white">{item.caption}</h1>
            <Link to={`${item.link}`}><Button size="xl" color='primary' className={'btn-pill px-5 py-2 mt-4'}>Read more</Button></Link>
            
            </div>
          </CarouselItem>
        );
      });
    
      return (
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          {slides}
          <CarouselControl className="pl-5" direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl className="pr-5" direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
      );
}
export default YmCarousel;