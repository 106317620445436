import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import headerJpg from '../../assets/img/youmoni-our-promise.jpg';
import headerWebp from '../../assets/img/youmoni-our-promise.webp';
import dividerJpg from '../../assets/img/youmoni-industry.jpg';
import dividerWebp from '../../assets/img/youmoni-industry.webp';
import { HeaderImage, YmHeaderProps } from '../../components/YmHeader';

import { BgColor } from '../../models/BgColor';
import { YmImageBlock } from '../../components/YmImageBlock';
import { YmCollapseRow } from '../../components/YmCollapseRow';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

export const Header: React.FunctionComponent<YmHeaderProps> = (props) => {
    return <div style={{ position: 'relative' }}>
        {props.media}
        <section className={"px-3 px-md-5 h-50"}>
            <Container fluid={'xl'} className="">
                <Row className="px-0">
                    <div className="header-text absolute-h-center">
                        <div>
                            <h1 className={'text-white'}>{props.title}</h1>
                        </div>
                    </div>
                </Row>
            </Container>
        </section>
    </div>
}

const IntroSection = () => {
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"IoT brings digital transformation and automation to the physical world"}
        ingress={<div>
            <p>With your things connected and its data stored you will find new insights. You will learn how your products really work and behave in different situations and why they fail. You will even learn that they don't work as expected better in some scenarios worse in other. In short, connected products will give you new insights that will improve your offerings and understand how your customers really use them. </p>
            <p>Youmoni also offers to combine your own data with other external data e.g. weather data, financial data, geo data and or location data. By combining your own product data with external data you will understand even more. Maybe the product fails when its high humidity outside, or when the temperature is above 35 Celcius. </p>
            <p>If you partner with other companies you can also use one anothers data by sharing data in the Youmoni cloud. </p>
            <p>More data will bring more clarity and it will be your new reality.</p>
            <p> <Link to="/solutions">Learn more about or solutions<FontAwesomeIcon className="mx-2" size={'1x'} icon={faLongArrowAltRight}></FontAwesomeIcon></Link></p>
        </div>}
        content={
            <section className="px-0 px-lg-5">
                <Container fluid="xl">
                    <Row>
                        <Col sm="12" lg="6" className="px-3 px-md-5 pb-5 pl-0 pl-lg-3">
                        </Col>
                    </Row>
                </Container>
            </section>
        }
    >
    </YmCollapseRow>
}

const FullSizeImage = (props:any): JSX.Element => {
    const dividerImage = props.supportsWebp ? dividerWebp :dividerJpg;
    return <Container fluid>
        <Row>
            <Col xs="12" className={'p-0'}>
                <YmImageBlock
                    media={(<div style={{ height: '50vh', width: '100%', backgroundPosition: 'center', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url('${dividerImage}')` }}></div>)}
                ></YmImageBlock>
            </Col>
        </Row>
    </Container>
}

const ExampleSection = () => {
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"Real World Example"}
        ingress={<div>
            <p>
                A pump motor is placed in the basement of a building and shall pump water if the basement is flooded. The pump motor is online via Youmoni edge gateway. It has connected a temp sensor, a relay that controls the electricity to the motor and a flood sensor on the floor.
    
            </p>
            Every time the floor is flooded Youmoni monitors the flooding status, measures the electricity performance to the motor and the motors temperature. The flooding pattern repeats quite regular, every month.
            <p>

            </p>
            <p>
                One morning the motor starts, but the flood sensor reports no water and the motor speeds with a higher rpm (casing it to be warm) because it has no water to pump. The AI sees the anomaly and Reacts, It shuts down the motor and reports the action via a notification in the property's online dashboard and send an SMS to the service organisation that the basement pump needs maintenance.
            </p>

            <p>
                The process will  result in lower maintenance cost because the pump was rescued from overheating and the service organization can gfive service to the flood detector in time.
            </p>
            <p>
            <p> <Link to="/solutions">Learn more about or solutions<FontAwesomeIcon className="mx-2" size={'1x'} icon={faLongArrowAltRight}></FontAwesomeIcon></Link></p>
            </p>

        </div>}
        content={
            <section className="px-0 px-lg-5">
                <Container fluid="xl">
                    <Row>
                        <Col sm="12" lg="6" className="px-3 px-md-5 pb-5 pl-0 pl-lg-3">
                        </Col>
                    </Row>
                </Container>
            </section>
        }
    >
    </YmCollapseRow>
}

const CloudDiscover: React.FC<{ match: any }> = (props:any) => {
    const headerImage = props.supportsWebp ? headerWebp : headerJpg;
    return (<div className="discover">
        <Header {...props} title="Let the cloud discover, notify and control" media={<HeaderImage image={headerImage}></HeaderImage>}></Header>
        <IntroSection></IntroSection>
        <FullSizeImage></FullSizeImage>
        <ExampleSection></ExampleSection>
    </div>
    );
}


export default CloudDiscover;    