import React from 'react';
import Main from '../components/Main';
import Content from '../components/Content';
import { YmFooter } from '../components/YmFooter';

const ContentLayout: React.SFC = (props) => {
    return <React.Fragment>
        {
            <div className="wrapper">
                <Main>
                    <Content>
                        {props.children}
                    </Content>
                </Main>
                <YmFooter></YmFooter>
            </div>
        }
    </React.Fragment>
}
export default ContentLayout;