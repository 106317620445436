import React from 'react';
import { translate } from 'react-polyglot';
import Polyglot from 'node-polyglot';
import texts from '../../content/Texts.json'

const YmAppText: React.FunctionComponent<{ name: string, t: any }> = (props) => {
    const { name, t } = props;
    return <span>{t(name)}</span>
}

export const GetText = (name:string ) => {
    let polyglot = new Polyglot({phrases:texts});
    let text = polyglot.t(name);
   return text;
  }

export default translate()(YmAppText)
