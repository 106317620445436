import React from "react";
import { Container, Row, Col } from "reactstrap";
import { YmTextBlock } from "../../components/YmTextBlock";
import { BgColor } from "../../models/BgColor";
import TopNavigation from "../../components/TopNavigation";
import { YmImageBlock } from "../../components/YmImageBlock";

import youmoniInvite from "../../assets/img/smart-city/youmoni-invite.jpg";
import boardPng from "../../assets/img/smart-city/youmoni-board.png";
import appScreenPng from "../../assets/img/smart-city/youmoni-app-screen.png";
import airQualityMonitoring1 from "../../assets/img/smart-city/youmoni-air-quality-monitoring-1.png";
import airQualityMonitoring2 from "../../assets/img/smart-city/youmoni-air-quality-monitoring-2.png";
import airQualityMonitoring3 from "../../assets/img/smart-city/youmoni-air-quality-monitoring-3.png";
import airQualityMonitoring4 from "../../assets/img/smart-city/youmoni-air-quality-monitoring-4.png";
import { YmSection } from "../../components/YmSection";
import { SmartCityScheduleForm } from "../../Forms/SmartCityScheduleForm";

import "./SmartCity.css";

const Header = (props: any): JSX.Element => {

  return <div>
    <TopNavigation {...props} bgColor={BgColor.WHITE}></TopNavigation>
    <section className="px-3 px-md-5">
      <Container fluid>
        <Row style={{ marginTop: "90px" }}>
          <Col className="px-0 pb-2 pb-md-0" sm="12">
            <YmImageBlock
              media={(
                <div className={"position-relative"}>
                  <img
                    src={youmoniInvite}
                    className="w-100"
                  ></img>
                </div>
              )}
            ></YmImageBlock>
          </Col>
        </Row>
      </Container>
    </section>
  </div>;
};

const SmartCityBody = (props: any): JSX.Element => {
  return (
    <section className="py-4 px-3 px-md-5">
      <Container className="smart-city-body mt-5">
        <Row>
          <p className="display-4 text-primary font-weight-bold">Youmoni at Smart City Conference & Expo 2023</p>
          <p className="h1 text-black font-weight-bold">
            Youmoni will introduce a series of powerful solutions for smarter properties and a more sustainable urban
            environment at Stockholm Smart City Conference & Expo. Come meet us on May 3-4 at Kistamässan, Stockholm.
          </p>
        </Row>
        <Row>
          <Col xs={12} sm={9} className="px-0">
            <YmTextBlock
              bgColor={BgColor.LIGHT}
              header="NEWS – Youmoni Living App"
              content={(
                <>
                  <p>
                    At the Stockholm Smart City 2023 Conference & Expo, Youmoni introduces Youmoni Living App – a new,
                    smart solution for bringing properties online. The cloud platform gives property owners and housing
                    cooperatives an easy way to get started with app-based social, and service community functions for
                    people living or working in a property.
                  </p>
                  <p>
                    Index Residence, a property developer, will be in the Youmoni booth (E:20) to showcase “Touch by
                    Index” service concept based on the new platform.
                  </p>
                  <p>
                    Youmoni and Index will also showcase a path towards the introduction of new digital services, such
                    as integrated online apartments that deliver important information for the residents and property
                    owners.
                  </p>
                </>
              )}
            ></YmTextBlock>
          </Col>
          <Col xs={12} sm={3} className="p-0 justify-content-center">
            <div className="d-flex flex-column align-items-center my-auto h5 text-center">
              <img
                className="mb-3 container-sm col-sm-12 col-md-6 px-0 mx-auto" src={appScreenPng}
                alt="app-screen-png"
              ></img>
              <p className="my-0">
                The image shows the first release of “Touch by Index” smart property and concierge app using Youmoni
                platform.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={9} className="px-0">
            <YmTextBlock
              bgColor={BgColor.LIGHT}
              header="NEWS – Youmoni Sense "
              content={(
                <>
                  <p>
                    Youmoni introduces Youmoni Sense – a multi-purpose IoT sensor platform featuring controller and
                    gateway for several use cases and industries. Its design, integrated sensors and relays make it
                    ideal in smart city solutions and property projects for monitoring and controlling things and
                    machines. Using one type of sensor for multiple use cases makes service and roll out easier and more
                    cost effective.
                  </p>
                  <p>
                    The new sensor platform will be introduced at Stockholm Smart City 2023 Conference & Expo in the
                    Youmoni booth (E:20). Two use cases will be showcased: Youmoni Sense acting as a smart property IoT
                    gateway for online apartments, and in a water quality and water level metering application using the
                    onboard radar sensor from our partner Acconeer.
                  </p>
                  <p>
                    The sensor platform is integrated with the Youmoni IoT cloud and supports Wi-Fi and Bluetooth mesh
                    connectivity with 4g/5g modem as optional add-on.
                  </p>
                </>
              )}
            ></YmTextBlock>
          </Col>
          <Col xs={12} sm={3} className="p-0 d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center my-auto h5 text-center">
              <img
                className="mb-3 container-sm col-sm-12 col-md-6 px-0 mx-auto" src={boardPng}
                alt="app-screen-png"
              ></img>
              <p className="my-0">
                Youmoni Sense – multi-purpose sensor platform, gateway and controller with onboard sensors, relays and
                communication designed for smart city, property and industry IoT applications.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={9} className="px-0">
            <YmTextBlock
              bgColor={BgColor.LIGHT}
              header="Air Quality Monitoring"
              content={(
                <>
                  <p>
                    People living in cities as well as public authorities want and need more accurate data about our
                    local living environment, especially air quality directly affecting our health.
                  </p>
                  <p>
                    In the Youmoni booth(E:20) at Stockholm Smart City Conference & Expo current air quality inside and
                    outside the exhibition will be shown.
                  </p>
                  <p>
                    With our partners we make it possible for property owners and cities to measure air quality more
                    easily. Youmoni delivers ready-to-use solutions that include hardware, Youmoni IoT cloud, and apps.
                    Our partners Small Data Garden and Sensorbee delivers best in class LoRa and 4g connected air
                    quality measurement sensors. Cities can easily present the live air quality data in city apps and
                    web sites and property owners can display air quality in Youmoni Living app.
                  </p>
                  <p>
                    For property owners it’s important to show that the air ventilation systems are working and at the
                    same time save money by planning filter changes related to real data. Cities can follow up and
                    measure trends and be compliant to Agenda 2030.
                  </p>
                </>
              )}
            ></YmTextBlock>
          </Col>
          <Col xs={12} sm={3} className="p-0 justify-content-center">
            <div className="d-flex flex-column align-items-center my-auto text-center h5">
              <div className="mb-3 grid grid-cols-2">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="container-sm px-0" src={airQualityMonitoring1}
                    alt="air-quality-monitoring-1"
                  ></img>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="container-sm px-0" src={airQualityMonitoring2}
                    alt="air-quality-monitoring-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="brand-1 w-auto container-sm px-0 mx-auto my-auto" src={airQualityMonitoring3}
                    alt="air-quality-monitoring-3"
                  ></img>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="brand-2 w-auto container-sm px-0 mx-auto my-auto" src={airQualityMonitoring4}
                    alt="air-quality-monitoring-4"
                  ></img>
                </div>
              </div>
              <p>
                Youmoni Partners Sensorbee (4g) and Small Data Garden (LoRa) Air quality sensors are integrated with
                Youmoni IoT Platform and Youmoni Living App for properties.
              </p>
              <p>
                Sensorbee air quality sensor targets outdoor property, shopping malls and smart city measurements e.g.,
                trains stations, ports etc. using 4g connectivity and solar power stations.
              </p>
              <p className="my-0">
                Small data garden sensors are ideal for indoor air quality in properties conference rooms, and
                apartments etc. with battery driven sensors that uses LoRa connectivity.
              </p>
            </div>
          </Col>
          <Col xs={12} className="p-0 d-flex justify-content-center">
            <a
              href="https://www.trippus.se/web/Registration/Registration.aspx?view=registration&idcategory=AB0ILBBF6EObdTMBZ-82VckROqjavjB4zXJBFphyjhxpWIw0J0H0BClNSYk2kMCYyJLwH2d39Zse&ln=eng&exhibitorwebId=AB0ILBB906W3aNr6mMLiT5AlOhGTpLOZCbTqV_QtLPhaujJqV7hw3DfD2o8u4DnbaovXUa1XQKj9"
              className="my-6 display-4 text-primary font-weight-bold underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign up here for a free ticket!
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const SmartCitySchedule = (props: any): JSX.Element => {
  return (
    <YmSection
      bgColor={BgColor.PRIMARY}
      fluid="xl"
      name=""
      header="Schedule a meeting with the Youmoni Team"
    >
      <Row>
        <Col sm="12" md="6" lg="6" className="p-0">
        </Col>
        <Col sm="12" md="6" className="p-0 order-1 order-md-2">
          <SmartCityScheduleForm></SmartCityScheduleForm>
        </Col>
      </Row>
    </YmSection>
  );
};

const softwareRef = React.createRef<HTMLDivElement>();
const hardwareRef = React.createRef<HTMLDivElement>();
const scrollInto = (location: any) => {
  if (location && location.hash) {
    let node: HTMLDivElement | null = null;
    switch (location.hash) {
      case "#software":
        node = softwareRef.current;
        break;
      case "#hardware":
        node = hardwareRef.current;
        break;
      default:
        break;
    }
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  }
};

const SmartCity: React.FC<{ match: any, location: any }> = (props) => {

  scrollInto(props.location);

  return (<div className="products">
      <Header {...props}></Header>
      <SmartCityBody {...props}></SmartCityBody>
      <SmartCitySchedule></SmartCitySchedule>
    </div>
  );
};

export default SmartCity;
