
import React from 'react';
import { Container, Row } from "reactstrap";
import { YmHeaderProps } from "./";
import TopNavigation from '../TopNavigation';
import { Link } from 'react-router-dom';

import smVideo from '../../assets/video/youmoni-sm.mp4';
import lgVideo from '../../assets/video/youmoni-lg.mp4';
import xlVideo from '../../assets/video/youmoni-xl.mp4';
import useWindowDimensions from '../../utils/useWindowDimensions';

export const HeaderImage: React.FC<{ image: string }> = (props) => {
    let coverStyle = {
        width: '100%',
        height: '80vh',
        backgroundImage: `url('${props.image}')`
    }
    return <div>
        <div style={{ minHeight: '500px', height: "80vh", width: '100%', position: 'absolute', top: "0", backgroundColor: "#000", opacity: ".3" }}></div>
        <div className={'cover-fixed'} style={coverStyle}></div>
    </div>
}

export const HeaderVideo: React.FC = (props) => {

    const { width } = useWindowDimensions();
    let source = width < 993 ? smVideo : width < 1200 ? lgVideo : xlVideo;
    let style = width < 768 ? { width: '100%' } : { minWidth: '100%', minHeight: '100%' };
    let video = <div className="video-wrapper" ><video className={'header-video'} style={style} src={source} muted={true} playsInline={true} autoPlay={true} loop></video></div>
    return <div>{video}</div>
}

const HeaderLinks: React.FC<{ links: any[] }> = (props) => {
    return <Row className="px-0">
        <div className="navHeader" style={{ position: 'absolute', bottom: '10px' }}>
            <ul className="d-inline-flex list-unstyled">
                {
                    props.links.map((link, index) => {
                        return <li key={`link_${index} `} className="mr-5 text-decoration-none"><Link className={'headerLink'} to={link.to}>{link.text}</Link></li>
                    })
                }

            </ul>
        </div>
    </Row>
}

export const YmHeader: React.FunctionComponent<YmHeaderProps> = (props) => {
    return <div style={{ position: 'relative' }}>
        {props.media}
        <TopNavigation {...props}></TopNavigation>
        <section className={"px-3 px-md-5 h-100"}>
            <Container fluid={'xl'} className="">
                <Row className="px-0">
                    <div className="header-text absolute-h-center">
                        <div>
                            <h1 className={'text-white'}>{props.title}</h1>
                        </div>
                    </div>
                </Row>
                {
                    props && props.links && props.links.length > 0 &&
                    <HeaderLinks links={props.links}></HeaderLinks>
                }
            </Container>
        </section>
    </div>
}

export default YmHeader;