import React from 'react';
import { Row, Col } from "reactstrap";
import { YmSection } from '../components/YmSection';
import { BgColor } from '../models/BgColor';
import { ContactForm } from '../Forms/ContactForm';

export const ContactUs: React.FC = (props) => {
  return <YmSection
    bgColor={BgColor.PRIMARY}
    fluid={'xl'}
    name={'Contact'}
    header={`Don't hesitate to contact us in any matter related to IoT technology or help in projects with any type of connected product.`}>
        <Row>
            <Col sm="12" md="6" lg="6" className="p-0">
            </Col>
            <Col sm="12" md="6" className="p-0 order-1 order-md-2">
            <ContactForm></ContactForm>
            </Col>
        </Row>
    </YmSection>
}