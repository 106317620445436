import React from 'react';
import { Row, Col } from "reactstrap";
import { YmTextBlock } from '../components/YmTextBlock';
import { YmSection } from '../components/YmSection';
import { BgColor } from '../models/BgColor';
const members = [
    {
        title: 'Johan Edgren',
        list: ['MD', 'Sales and Solution', 'Partners', 'Tech']
    },
    {
        title: 'Lizzie Elf',
        list: ['Economy ', 'Administration']
    },
    {
        title: 'Odd Möller',
        list: ['IoT Cloud back end.', 'System Architect lead', 'Special Customer Projects']
    },
    {
        title: 'Martin Zachrison',
        list: ['IoT Cloud back end.', 'Senior developer', 'Security']
    },
    {
        title: 'Anders Ekbom',
        list: ['Key account', 'Senior project manager', 'Hardware solutions', 'Platform scrum master']
    },
    {
        title: 'Jonas Petersson',
        list: ['UI / UX lead ', 'Apps', 'Front end development']
    },
    {
        title: 'Erika Sönnergren',
        list: ['HR and office', 'Special projects', 'Project administration',]
    },
    {
        title: 'Fredrik Gustavsson',
        list: ['Senior developer', 'Back end and front end', 'Role- and access management']
    },
    {
        title: 'Johan Fisher',
        list: ['Expert consulting team', 'Project Management', 'Business Processes', 'Roll out and logistics']
    },
    {
        title: 'Nils Gawell',
        list: ['Mechanical- and electronics engineering', 'Production technology and design', 'Special prototypes ']
    },
    {
        title: 'Patrik Andersson',
        list: ['Embedded hardware engineer', 'Hardware design', 'IoT Gateways', 'Sensor technology']
    }
]

export const Team: React.FC = (props) => {
    return <YmSection
        bgColor={BgColor.WHITE}
        fluid={'xl'}
        name={'Team'}
        header={`Every person at Youmoni has a passion for technology and a desire to solve difficult problems. Together, we invent, design and engineer new technologies and products that have lasting business impact for our customers.`}
    >
        <Row>
            {
                members.map((member, index) => {
                    return <Col key={`member_${index}`} className="mb-3" xs="12" sm="6" md="4" lg="3" xl="2">
                        <YmTextBlock
                            header={member.title}
                            content={(
                                <ul style={{ fontSize: '16px' }} className={'list-unstyled'}>
                                    {
                                        member.list.map((item, index) => {
                                            return <li key={`list_item_${index}`}>{item}</li>
                                        })
                                    }
                                </ul>
                            )}
                        ></YmTextBlock>
                    </Col>
                })
            }
        </Row>
    </YmSection>
}