import React from 'react';
import { Row, Col } from 'reactstrap';
import { YmHeader, HeaderImage } from '../../components/YmHeader';
import { YmTextBlock } from '../../components/YmTextBlock';
import { BgColor } from '../../models/BgColor';
import { YmSection } from '../../components/YmSection';
import { YmCollapseRow } from '../../components/YmCollapseRow';
import { ContactForm } from '../../Forms/ContactForm';

import headerImgJpg from '../../assets/img/youmoni-business.jpg';
import headerImgWebp from '../../assets/img/youmoni-business.webp';

const IntroSection = () => {
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"Pay as you go. Free IoT cloud tiers available."}
        ingress={<div>
            <div><YmTextBlock
                header={"It's easy doing business with us."}
                content={
                    <div>
                        <p>Contact us and tell us about your challenges and projects. Our business model is easy.</p>
                    </div>
                }>
            </YmTextBlock>
            </div>
            <div><YmTextBlock
                header={"You pay as you go for Youmoni IoT cloud."}
                content={
                    <div>
                        <p>With Youmoni you pay a monthly subscription fee per legal unit and subscription fee per thing including data package and connectivity. Optional Cloud services can be activated when needed</p>
                        
                       
                    </div>
                }>
            </YmTextBlock>
            </div>
            <div><YmTextBlock
                header={"Pricing"}
                content={
                    <div>
                        <p>Free tiers are available for trials and proof of concepts. </p>
                        <p>Business tier starting from 200 Euro per month.</p>
                    </div>
                }>
            </YmTextBlock>
            </div>
            <div><YmTextBlock
                header={"Expertise"}
                content={
                    <div>
                        <p>Proof of concept projects can often be delivered in weeks and offered with a fixed price.</p>
                    </div>
                }>
            </YmTextBlock>
            </div>
            <div><YmTextBlock
                header={"Implementation, adaptation and integration projects"}
                content={
                    <div>
                        <p>Implementations are managed and priced according to normal consulting procedures with either Youmoni export consultants and or with partner resources.</p>
                    </div>
                }>
            </YmTextBlock>
            </div>
            <div><YmTextBlock
                header={"Managed IoT"}
                content={
                    <div>
                        <p>With our Managed IoT Service everyting is included as a service. It’sdelivered according to an SLA specifying cloud operation level and monitoring and can includeintegrated field service processes, hardware and spare part supply andmanaging extensive support processes including 3: rd party organisations. In live production a minimum support and service desk fee for one business user are mandatory.</p>
                    </div>
                }>
            </YmTextBlock>
            </div>

        </div>}>
    </YmCollapseRow>
}


const ContactUs = () => {
    return <YmSection
    bgColor={BgColor.PRIMARY}
    fluid={'xl'}
    name={'Contact'}
    header={`Don't hesitate to contact us on any matter related to IoT technology or help in projects with any type of connected product.`}>
        <Row>
            <Col sm="12" md="6" lg="6" className="p-0">
            </Col>
            <Col sm="12" md="6" className="p-0 order-1 order-md-2">
            <ContactForm>   </ContactForm>
            </Col>
        </Row>
    </YmSection>
}

const Business: React.FC<{ match: any }> = (props:any) => {
    const headerImage = props.supportsWebp ? headerImgWebp : headerImgJpg;

    return (<div>
        <YmHeader {...props} title="It's easy doing business with us" media={<HeaderImage image={headerImage}></HeaderImage>}></YmHeader>
        <IntroSection></IntroSection>
        <ContactUs></ContactUs>
    </div>
    );
}

export default Business;    