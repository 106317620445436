import React from 'react';
import { YmHeader, HeaderImage } from '../../components/YmHeader';
import { YmSection } from '../../components/YmSection';
import { BgColor } from '../../models/BgColor';
import { Row,Col, Container } from 'reactstrap';
import { YmTextBlock } from '../../components/YmTextBlock';
import { YmImageBlock } from '../../components/YmImageBlock';

import partnerJpg from '../../assets/img/partners/youmoni-partner.jpg';
import hardwareJpg from '../../assets/img/partners/youmoni-hardware.jpg';
import partnerWebp from '../../assets/img/partners/youmoni-partner.webp';
import hardwareWebp from '../../assets/img/partners/youmoni-hardware.webp';
import axotec from '../../assets/img/partners/youmoni-partner-axotec.png';
import cupole from '../../assets/img/partners/youmoni-partner-cupole.svg';
import cypoint from '../../assets/img/partners/youmoni-partner-cypoint.svg';
import digitalmatter from '../../assets/img/partners/youmoni-partner-digitalmatter.png';
import enea from '../../assets/img/partners/youmoni-partner-enea.svg';
import kontron from '../../assets/img/partners/youmoni-partner-kontron.png';
import mtx from '../../assets/img/partners/youmoni-partner-mtx.png';
import stsolutions from '../../assets/img/partners/youmoni-partner-st.png';
import stretch from '../../assets/img/partners/youmoni-partner-stretch.svg';
import syncron from '../../assets/img/partners/youmoni-partner-syncron.png';
import visuellplanering from '../../assets/img/partners/youmoni-partner-visuell-planering.png';

export const BusinessPartners: React.FC = (props) => {
        const padding = 'pr-6 py-4'
        return <YmSection
        bgColor={BgColor.LIGHT}
        fluid={'xl'}
        name={''}
        header={`Business Partners`}>
            <Row>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.cupole.se">
                    <span style={{ display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${cupole}')` }}></span>
                    </a>
                        
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="Cupole"
                            content={(`Management consultants. Experts in business transformation and process optimization. IoT will drive the next wave of digitization extending the digital processes to the physical space bringing business assets online. Together Cupole and Youmoni has a complete set of tools to deliver real business transformation from business analysis, through proof of concepts and tuned IoT packages to organizational readiness and roll out.`)}
                        ></YmTextBlock> 
                    </Col>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.stretch.se">
                        <span style={{  display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${stretch}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="Stretch"
                            content={(`Business- and IT consultants. Optimizing business processes using Salesforce, SAP, Office 365, Microsoft Azure. Stretch is a Youmoni business, implementation and integration partner. Together we integrate Youmoni IoT cloud and solutions with SAP and Office 365 processes. To be able to digitise the whole process from sensor data to business actions the combined IoT and ERP expertise is necessary . Together we deliver ready to go IoT and ERP integrated processes with focus on remote monitoring, field service, predictive maintenance and vendor managed inventories (VMI).`)}
                        ></YmTextBlock> 
                    </Col>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://visuellplanering.se">
                        <span style={{ display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${visuellplanering}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="Visuell Planering"
                            content={(`Specialists within digital project control. Together we deliver solutions where integrated sensor data automatically updates status in digital project management systems. Project owners and managers can get a live status of how e.g. a construction project is performing with real time sensor data as input. Together we deliver solutions for the public sector, construction-, and infrastructure industry`)}
                        ></YmTextBlock> 
                    </Col>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.enea.com">
                        <span style={{ display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${enea}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="Enea"
                            content={(`Specialists in telecom, networking and embedded tech. Enea is a business partner and provide resources for larger customization projects and are also a partner within embedded tech and virtualization technology for edge computing.`)}
                        ></YmTextBlock> 
                    </Col>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://cypoint.se">
                        <span style={{ display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${cypoint}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="Cypoint"
                            content={(`E-business and PIM consultants. Experts in e- business implementation and product information management systems. Implementation partners when e-business meets IoT.`)}
                        ></YmTextBlock> 
                    </Col>
            </Row>     
        </YmSection>
    }

export const HardwarePartners: React.FC = (props) => {
        const padding = 'pr-6 py-4'
        return <YmSection
        bgColor={BgColor.WHITE}
        fluid={'xl'}
        name={''}
        header={`Hardware Partners`}>
            <Row>
                    <Col sm="12" md="6" xl="4"  className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.kontron.com">
                        <span style={{ display:'inline-block' ,height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${kontron}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="Kontron"
                            content={(`Advanced edge computers and connected IoT Gateways for industry, property, trains, vehicles. Some of Kontrons edge computers and gateways are integrated with Youmoni cloud using Youmoni edge software.`)}
                        ></YmTextBlock> 
                    </Col>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.digitalmatter.com">
                        <span style={{ display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${digitalmatter}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="Digital Matter"
                            content={(`Focus on tracking and delivers tracking hardware with GPS and 2g/3g/4g, 4G CAT M1 and LoRa connectivity. A range of Youmoni integrated trackers for assets and vehicles are available. Battery powered trackers with up to 10 years of battery life. Satellite options available. Together we deliver standardised and customised business integrated tracking solutions to the market.`)}
                        ></YmTextBlock> 
                    </Col>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://mtxm2m.com">
                        <span style={{ display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${mtx}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="Mtx"
                            content={(`Smart IoT modems and gateways for smart city, metering, vehicles and remote monitoring. Many standardized edge use cases can be solved with quick configuration.`)}
                        ></YmTextBlock> 
                    </Col>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.axotec.de">
                        <span style={{ display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${axotec}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="Axotec"
                            content={(`IoT gateways for vehicles, industry and property. Axotec IoT gateways are integrated with Youmoni Cloud using Youmoni edge software. Axotec Technologies GmbH consists of a team of experienced specialists for embedded computer technology for applications in the area of the Internet-of-things, machine and plant monitoring as well as internet-based data logging. With innovative, highly reliable and long-lasting standard products as well as with customer-specific platforms, Axotec enables fast and reliable implementation of demanding requirements in market-oriented solutions. Our customers benefit from fast implementation on the market, low total cost-of-ownership and long-term product stability.`)}
                        ></YmTextBlock> 
                    </Col>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.stsolutions.se">
                        <span style={{ display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${stsolutions}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.LIGHT}
                            header="St Solutions"
                            content={(`Flexible IoT GW with optional sensor and digital I/O hubs. `)}
                        ></YmTextBlock> 
                    </Col> 
            </Row>     
        </YmSection>
    }

export const SoftwarePartners: React.FC = (props) => {
        const padding = 'pr-6 py-4'
        return <YmSection
        bgColor={BgColor.PRIMARY}
        fluid={'xl'}
        name={''}
        header={`Analysis and predictive maintenance`}>
            <Row>
                    <Col sm="12" md="6" xl="4" className={`${padding}`}>
                    <div className="py-4 px-5 pb-5 w-100 text-center">
                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.syncron.com">
                        <span style={{ display:'inline-block', height: '100px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${syncron}')` }}></span>
                    </a>
                    </div>
                        <YmTextBlock
                            bgColor={BgColor.PRIMARY}
                            header="Syncron"
                            content={(`Experts in and delivers systems for analysis and optimization of maintenance processes, spare part supply and predictive maintenance. Together we can bring digitized maintenance processes to customers that don't have any data from their machines or assets and predictive maintenance to Youmonis customers. `)}
                        ></YmTextBlock> 
                    </Col>
                    
            </Row>     
        </YmSection>
    
    }

const FullScreenImage: React.FC<{image:any}>  = (props) => {
    return   <Container fluid>
    <Row>
        <Col xs="12" className={'p-0'}>
            <YmImageBlock
                media={(
                    <div className="position-relative">
                        <div className={'cover-fixed'} style={{ height: '40vh', width: '100%', backgroundImage: `url(${props.image})` }}></div>
                    </div>
                )}
            ></YmImageBlock>
        </Col>
    </Row>
</Container>
}

const Partner : React.FC<{match:any}> = (props:any) => {
    const partnerImage = props.supportsWebp ? partnerWebp : partnerJpg;
    const hardwareImage = props.supportsWebp ? hardwareWebp : hardwareJpg;
        return (<div className="about">
                <YmHeader
                 {...props}
                title="We partner with smart people working on solving interesting problems."
                media={<HeaderImage image={partnerImage}></HeaderImage>}
                ></YmHeader>
                <BusinessPartners></BusinessPartners>
                <FullScreenImage image={hardwareImage}></FullScreenImage>
                <HardwarePartners></HardwarePartners>
                <SoftwarePartners></SoftwarePartners>
            </div>
        );
    }

export default Partner;    