import { ParagraphEnum } from "../../../enums/Career.enums";
import { CareerPageContent } from "../../../interfaces/Career.interfaces";

export const careerPageContent: CareerPageContent[] = [
  {
    title: "Remote work and language",
    paragraphs: [
      {
        type: ParagraphEnum.text,
        content: "We strive to work flexible; some work can be carried out remote with some days are required at the office in Stockholm Sweden. Typically, the product team are located 2-3 days at the office to collaborate. Some positions can be fully remote, and some is more office located and the expert consulting team sometimes work at customers sites and or premises.",
      },
    ],
  },
  {
    title: "Out tech and development are divided into 3 main departments.",
    paragraphs: [
      {
        type: ParagraphEnum.list,
        listItems: [
          `<b>Expert Consulting Team</b>: Implements and delivers solutions to our customers and assist partners.`,
          `<b>Platform Team</b>: Develops our core multi-tenant cloud platform with a back end written in Scala and user interfaces in React and React Native.`,
          `<b>Embedded Team</b>: Develops Youmoni Embedded, custom hardware and embedded software, mainly C and C++ and Java. `,
        ],
      },
      {
        type: ParagraphEnum.text,
        content: "Our Teams is dedicated to pushing the boundaries of innovation, creating nextgeneration IoT cloud backend with fast and responsive user interfaces for our platforms, and designing intuitive native applications that redefine our customers user engagement.",
      },
      {
        type: ParagraphEnum.text,
        content: "Apply now to be part of our innovative and driven team!",
      },
    ],
  },
  {

    id: "job_opening_junior_and_or_experienced_front_end_developer",
    title: "Job Opening: Junior and or experienced Front End Developer",
    paragraphs: [
      {
        type: ParagraphEnum.text,
        content: "Are you a talented and ambitious Junior Front End Developer looking to join a dynamic and innovative Expert Consulting Team? Do you have a passion for crafting exceptional web-based user interfaces and native applications that deliver an unparalleled user experience? If you're ready to take your skills to the next level while collaborating with industry experts, we have the perfect opportunity for you!",
      },
    ],
    properties: [
      {
        propTitle: "Role and Responsibilities:",
        paragraphs: [
          {
            type: ParagraphEnum.text,
            content: "As a Junior Front End Developer on our Expert Consulting Team, you will:",
          },
          {
            type: ParagraphEnum.list,
            listItems: [
              "Adapt and develop new components in our Back End User interface, and Console and custom apps to our customers.",
              "Collaborate with cross-functional teams to design, develop, and maintain captivating web-based user interfaces and native iOS and Android apps.",
              "Contribute to the implementation of responsive and user interface designs using HTML, CSS, React, React Native, JavaScript, and TypeScript.",
              "Work closely with senior developers and architects to translate user requirements into technical solutions.",
              "Stay up-to-date with the latest front-end development trends and emerging technologies to drive continuous improvement.",
            ],
          },
        ],
      },
      {
        propTitle: "Requirements:",
        paragraphs: [
          {
            type: ParagraphEnum.list,
            listItems: [
              "Previous experience working on projects involving HTML, CSS, React, React Native, JavaScript, and TypeScript.",
              "Strong passion for front-end development and an eagerness to learn and grow in a collaborative team environment.",
              "Basic understanding of UI/UX principles and a keen eye for detail in design implementation.",
              "Communication skills and the ability to work effectively within a cross-functional team.",
              "A portfolio or examples of previous projects that showcase your front-end development skills and creativity.",
            ],
          },
        ],
      },
      {
        propTitle: "Preferred Qualifications:",
        paragraphs: [
          {
            type: ParagraphEnum.list,
            listItems: [
              "Familiarity with agile development methodologies.",
              "Experience with version control systems such as Git.",
              "Knowledge of web accessibility standards and best practices.",
              "Previous exposure to responsive and mobile-first design principles.",
              "Some knowledge of Docker and Nginx",
            ],
          },
        ],
      },
      {
        propTitle: "How to Apply:",
        paragraphs: [
          {
            type: ParagraphEnum.text,
            content: "If you are a results-driven, innovative Front End developer that wants to work both with Back End consoles, public web and native apps and has a track record of some projects, we would love to hear from you. Please submit your resume and a cover letter detailing your relevant experience and why you are a great fit for Youmoni.",
          },
          {
            type: ParagraphEnum.text,
            content: "Send your information to: career@youmoni.com",
          },
        ],
      },
    ],
  },
  {

    id: "job_opening_2_x_junior_backend_java/scala_developer – learn_from_the_best",
    title: "Job Opening: 2 x Junior Backend Java/Scala Developer – learn from the best",
    paragraphs: [
      {
        type: ParagraphEnum.text,
        content: "Are you passionate about backend development, microservices architecture, and cutting-edge technologies? Do you want to learn Scala? Join our dynamic team at Youmoni as a Junior Backend Java/Scala Developer. You will have the exciting opportunity to contribute to our Expert Consulting Team or Platform Team, working closely with our backend services developers with advanced microservices to deliver innovative IoT solutions.",
      },
      {
        type: ParagraphEnum.text,
        content: "If you have Java experience, we can teach you Scala! We have experts onboard and the most important thing is your openness to learn new and fast and that its exiting to get to learn a new language.",
      },
      {
        type: ParagraphEnum.text,
        content: `<b>Odd Möller</b> Youmonis Chief Platform Architect and a Java and Scala legend (<a href="https://www.youtube.com/watch?v=lcI-jmh5Cf0https://www.youtube.com/watch?v=lcI-jmh5Cf0" target="_blank">https://www.youtube.com/watch?v=lcI-jmh5Cf0</a>) with 30 years of back-end development will learn you!`,
      },
    ],
    properties: [
      {
        propTitle: "Key Responsibilities:",
        paragraphs: [
          {
            type: ParagraphEnum.list,
            listItems: [
              "Collaborate with experienced developers to design, develop, and maintain backend microservices using Java and/or Scala.",
              "Contribute to the architecture, implementation, and optimization of our platform's backend services.",
              "Work on projects related to our Expert Consulting or Platform Team, depending on your area of interest and expertise.",
              "Utilize your experience in Java/Scala and microservices to enhance the scalability, performance, and reliability of our applications.",
              "Explore and integrate cloud technologies, especially Amazon Web Services (AWS), to create resilient and efficient solutions.",
              "Engage with container technology like Docker to facilitate seamless deployment and management of microservices.",
              "Contribute to the ongoing evolution of our development processes, coding standards, and best practices.",
            ],
          },
        ],
      },
      {
        propTitle: "Qualifications:",
        paragraphs: [
          {
            type: ParagraphEnum.list,
            listItems: [
              "Solid foundation in backend development with Java and/or Scala, gained coursework, personal projects, or previous work experience.",
              "Familiarity with microservices architecture and its principles, with practical experience in designing or implementing microservices.",
              "Exposure to cloud platforms, particularly AWS, is a plus.",
              "Knowledge of SQL and Postgres database is a plus.",
              "Proficiency in Linux environments and command-line tools.",
              "Basic understanding of containerization technology, such as Docker.",
              "Strong problem-solving skills and a passion for learning new technologies and tools.",
              "Excellent teamwork and communication skills, with the ability to collaborate effectively within a cross-functional team.",
            ],
          },
        ],
      },
      {
        propTitle: "How to Apply:",
        paragraphs: [
          {
            type: ParagraphEnum.text,
            content: "If you are a result-driven, innovative Back End developer that wants to learn more Scala and with a passion for IoT and a track record of some projects, we would love to hear from you! Please submit your resume and a cover letter detailing your relevant experience and why you are a great fit for Youmoni.",
          },
          {
            type: ParagraphEnum.text,
            content: "Send your information to: career@youmoni.com",
          },
        ],
      },
    ],
  },
  {

    id: "job_opening_technical_digital_project_manager - io_t_cloud_platform",
    title: "Job Opening: Technical Digital Project Manager - IoT Cloud Platform",
    paragraphs: [
      {
        type: ParagraphEnum.text,
        content: "<b>Youmoni</b> is a leading innovator in the IoT industry, specializing in the development and implementation of our cutting-edge IoT Cloud Platform for a wide range of industries. We are seeking a dynamic and experienced Technical Digital Project Manager to join our Expert Consulting Team. As part of our team, you will play a pivotal role in driving the successful adaptation, implementation, and roll-out of our IoT solutions for our diverse portfolio of clients.",
      },
    ],
    properties: [
      {
        propTitle: "Role and Responsibilities:",
        paragraphs: [
          {
            type: ParagraphEnum.list,
            listItems: [
              "Collaborate with sales teams to understand customer requirements and create tailored IoT solutions using our IoT Cloud and edge stack. .",
              "Conduct comprehensive requirements analysis and translate client needs into actionable project plans.",
              "Break down projects into well-defined sprints, tasks, and timelines.",
              "Lead cross-functional teams of backend, frontend, and embedded developers in project execution.",
              "Manage end-to-end project lifecycle, from initiation to completion, ensuring deliverables are on time and within budget.",
              "Handle budgeting and reporting responsibilities, providing regular updates to stakeholders.",
              "Utilize project management tools such as Jira for task tracking and Confluence for documentation, Slack and Teams for cross team Collaboration.",
              "Foster a culture of collaboration, innovation, and continuous improvement within the project teams.",
              "Stay up-to-date with industry trends and technological advancements, especially in areas related to IoT, property tech, vehicles, energy solutions, sensors, and hardware.",
            ],
          },
        ],
      },
      {
        propTitle: "Qualifications:",
        paragraphs: [
          {
            type: ParagraphEnum.list,
            listItems: [
              "3-5 years of proven experience in managing digital complex projects, preferably, but not required, within the IoT domain.",
              "Strong understanding of digitalization, internet, web, e-business and IoT technologies and their applications in real-world scenarios.",
              "Proficiency in project management methodologies, use cases and tools, including Agile approaches.",
              "Excellent communication and interpersonal skills, capable of leading and motivating cross-functional teams.",
              "Demonstrated ability to manage budgets, prioritize tasks, and meet project deadlines.",
              "Practical knowledge of cloud infrastructure such as AWS, Linux, technical configurations, some programing, JSON, CSS, React, and or similar is a significant advantage.",
              "Practical knowledge or keen interest in domains such as property tech, vehicles, energy solutions, sensors, or hardware, electrical installations is a significant advantage.",
            ],
          },
        ],
      },
      {
        propTitle: "How to Apply:",
        paragraphs: [
          {
            type: ParagraphEnum.text,
            content: "If you are a results-driven, innovative Technical Digital Project Manager with a passion for IoT and a track record of successful project delivery, we would love to hear from you. Please submit your resume and a cover letter detailing your relevant experience and why you are a great fit for Youmoni.",
          },
          {
            type: ParagraphEnum.text,
            content: "Send your information to: career@youmoni.com",
          },
        ],
      },
    ],
  },
  {

    id: "job_opening_junior_embedded_software_engineer",
    title: "Job Opening: Junior Embedded Software Engineer",
    paragraphs: [
      {
        type: ParagraphEnum.text,
        content: `Are you a passionate and driven individual looking to kick-start your career as an Embedded Software Engineer? Do you have a keen interest in working at the intersection of software and hardware design? Youmonis is seeking a dynamic and motivated Junior Embedded Software Engineer to join our Embedded Engineer team.`,
      },
      {
        type: ParagraphEnum.text,
        content: `Youmoni is also a leading innovator in embedded IoT hardware with custom applications. We are specialized in developing cutting-edge embedded hardware with our own software platform and deliver standardized solutions that encompass various domains. Typical work in our projects include low level driver development to sensor integration and utilize wired communication like CAN Bus, Modbus and wireless technologies like Bluetooth, Wi-Fi, and the latest 4G/5G modems. In our state-of-theart lab facilitates we can test and verify hardware in real scenarios. Here we work hands-on and install prototypes and integrate sensors in collaboration with customers.`,
      },
    ],
    properties: [
      {
        propTitle: "Role and Responsibilities:",
        paragraphs: [
          {
            type: ParagraphEnum.text,
            content: `As a Junior Embedded Software Engineer, you will embark on an exciting learning journey, collaborating closely with experienced engineers to develop advanced embedded software. Your responsibilities will include:`,
          },
          {
            type: ParagraphEnum.list,
            listItems: [
              "Participating in the design, development, and testing of embedded software for Youmonis' IoT hardware and custom applications.",
              "Work with microcontroller programming, with a focus on ESP32, STM32, and Nordic platforms.",
              "Work with embedded application and driver development, sensor, and protocol integration, and implementing use cases with Bluetooth, Wi-Fi, and 4G/5G modems.",
              "Contributing to the creation and maintenance of software documentation.",
              "Exploring and learning FreeRTOS and other relevant technologies.",
              "Collaborating with the hardware design team, gaining exposure to PCB design concepts.",
            ],
          },
        ],
      },
      {
        propTitle: "Qualifications:",
        paragraphs: [
          {
            type: ParagraphEnum.text,
            content: "To excel in this role, you should possess the following qualifications:",
          },
          {
            type: ParagraphEnum.list,
            listItems: [
              "Proficiency in C and preferably C++ programming languages.",
              "Familiarity with microcontrollers, preferably ESP32, STM32, and/or Nordic platforms.",
              "Enthusiasm for learning and a strong desire to work in a collaborative team environment.",
              "Knowledge of FreeRTOS is a plus.",
              "Knowledge of Java is a plus.",
              "Experience or interest in hardware design and PCB layout is desirable.",
              "Strong problem-solving skills and attention to detail.",
            ],
          },
        ],
      },
      {
        propTitle: "Why Join Youmonis:",
        paragraphs: [
          {
            type: ParagraphEnum.text,
            content: `At Youmoni we offer a nurturing environment that promotes continuous learning and professional growth. As a Junior Embedded Software Engineer, you will have the opportunity to work alongside experienced engineers on cutting-edge projects, gaining invaluable hands-on experience. Our commitment to innovation, coupled with a culture of teamwork, makes Youmonis an ideal place to kick-start your career in the exciting world of embedded software and hardware design.`,
          },
          {
            type: ParagraphEnum.text,
            content: "If you're eager to embark on a journey of learning and innovation, while contributing to impactful projects, we invite you to apply and join our team of passionate engineers.",
          },
        ],
      },
      {
        propTitle: "How to Apply:",
        paragraphs: [
          {
            type: ParagraphEnum.text,
            content: "If you are a results-driven, innovative Embedded Software and or Hardware developer with a passion for IoT, we would love to hear from you. Please submit your resume and a cover letter detailing your relevant experience and why you are a great fit for Youmoni.",
          },
          {
            type: ParagraphEnum.text,
            content: "Send your information to: career@youmoni.com",
          },
        ],
      },
    ],
  },
];
