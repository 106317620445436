import React from 'react';
import { Row, Col } from 'reactstrap';
import { YmHeader, HeaderImage } from '../../components/YmHeader';
import { YmTextBlock } from '../../components/YmTextBlock';
import { BgColor } from '../../models/BgColor';
import { Team } from '../../content/Team';

import { YmSection } from '../../components/YmSection';

import aboutJpg from '../../assets/img/about/youmoni-about.jpg';
import aboutWebp from '../../assets/img/about/youmoni-about.webp';

import connect_gif from '../../assets/img/Youmoni_Connect.gif';
import visualize_gif from '../../assets/img/Youmoni_Visualise.gif';
import analyze_gif from '../../assets/img/Youmoni_Analyse.gif';
import react_gif from '../../assets/img/Youmoni_React.gif';

import { YmMap } from '../../components/YmMap';


import { ContactForm } from '../../Forms/ContactForm';
import { Link } from 'react-router-dom';

const AboutUs = (): JSX.Element => {
    const padding = 'pr-6 py-4'
    return <YmSection
    bgColor={BgColor.WHITE}
    fluid={'xl'}
    name={'About us'}
    header={`From transportation, to smart city, property, industry and automated retail – Youmoni provides managed IOT from sensor to business insights`}>
        <Row>
                <Col sm="12" md="6" xl="3" className={`${padding}`}>
                    <YmTextBlock
                        header={"Technology"}
                        content={
                            <p>
                                Youmoni delivers a flexible and scalable IoT cloud platform developed by our skilled tech team in Sweden. The IoT stack includes every layer needed to deploy a ready-to-use Youmoni IoT application, or quickly develop a custom IoT solution using our cloud services framework. We also deliver ready-to-use and integrated IoT hardware, gateways, trackers and sensors, including connectivity.
                                Read more under <Link to="/solutions">Solutions</Link> and <Link to="/products">Products</Link>.
                            </p>
                        }
                    ></YmTextBlock>
                </Col>
                <Col sm="12" md="6" xl="3" className={`${padding}`}>
                    <YmTextBlock
                        header={"Expertise"}
                        content={`The Youmoni business consulting team and partners can transform and digitize your business' physical processes, from analysis to real world IoT deployments. The projects can focus on business results from the start and deliver quick proof of concepts, rather than focusing on selecting platforms, hardware connectivity etc. in long-running analysis phases. The Youmoni consulting team with partners includes competence from business analysts, IoT experts, data and cloud experts, system architects, user interface- and app designers, connectivity experts, electronics- and mechanical design engineers.`}
                    ></YmTextBlock>
                </Col>
                <Col sm="12" md="6" xl="3" className={`${padding}`}>
                    <YmTextBlock
                        header={"Managed IoT"}
                        content={`Youmoni delivers IoT as a service end-to-end. The service includes cloud platforms, edge hardware, connectivity including e.g. needed SIM and/or other telecom network integrations. Our service delivery includes cloud operations, monitoring, field service and support processes, delivered with a pay-as-you-go subscription-based business model.`}
                    ></YmTextBlock>
                </Col>
                <Col sm="12" md="6" xl="3" className={`${padding}`}>
                    <YmTextBlock
                        header={"Business Areas"}
                        content={`Youmoni are today active in the Transportation & Logistics, Smart City & Property, Industry and Automated Retail sectors. We deliver ready-to-use fleet management, track'n'trace, remote monitoring, and remote retail applications.`}
                    ></YmTextBlock>
                </Col>
            </Row>

    </YmSection>
}

const WhatWeDo = (): JSX.Element => {
    return <YmSection
    bgColor={BgColor.PRIMARY}
    fluid={'xl'}
    name={'What we do'}
    header={`We connect your things and bring them online. Imagine. Monitor your things wherever they are.`}>
        <Row className={"mb-4"}>
            <Col sm="12" md="6" className={'order-1 order-md-1 pb-4 pb-md-0 my-auto'}><div className="text-center p-4 w-100"><img src={connect_gif} alt="connect" /></div></Col>
            <Col sm="12" md="6" className={'order-2 order-md-2 my-auto'}>
                <YmTextBlock header={"Connect"} 
                content={`We connect your things by adding sensors, or by using existing sensors and data interfaces. An IoT gateway or edge computer collects, filters and secures the data. The gateway is connected to a common wireless broadband service or a free data network and sends the data to the Youmoni IoT cloud where it’s stored, visualized, processed and analyzed.`} bgColor={BgColor.PRIMARY}></YmTextBlock>
                </Col>
        </Row>
        <Row className={"mb-4"}>
            <Col sm="12" md="6" className={'order-2 order-md-1 my-auto'}>
                    <YmTextBlock header={"Visualize"} 
                    content={`To maximize your insights, we visualize your data to make it easy to understand. The Youmoni Cloud has a ready-to-use user interface, where the location, sensor status and notifications for the connected things are presented. The web interface can easily be adapted with new components and branded with your company's style, and also supports white labelling.`} bgColor={BgColor.PRIMARY}>
                    </YmTextBlock>
            </Col>
            <Col sm="12" md="6" className={'order-1 order-md-2 pb-4 pb-md-0 my-auto'}><div className="text-center p-4 w-100"><img src={visualize_gif} alt="visualize" /></div></Col>
        </Row>
        <Row className={"mb-4"}>
            <Col sm="12" md="6" className={'order-1 order-md-1 pb-4 pb-md-0 my-auto'}><div className="text-center p-4 w-100"><img src={analyze_gif} alt="analyse" /></div></Col>
            <Col sm="12" md="6" className={'order-2 order-md-2 my-auto'}>
                <YmTextBlock header={"Analyze"} 
                content={`Once connected, all data is stored in the Youmoni Cloud. New insights and patterns will be recognised, improving your products and processes. Youmoni data can also be mixed with data from other sources. Combined, new discoveries will emerge. Data can be analyzed by Youmoni or exported to your external data lake for further insights. Data is fully separated between accounts, and always owned by our customers.`} bgColor={BgColor.PRIMARY}>
                </YmTextBlock></Col>
        </Row>
        <Row className={"mb-4"}>
            <Col sm="12" md="6" className={'order-2 order-md-1 my-auto'}>
                <YmTextBlock header={"React"} 
                content={`When products report data in near real time, the Youmoni Cloud will be able to discover harmful patterns and lets the system act before it’s too late. The Youmoni Cloud can be trained to react to changes in patterns and notify and control processes if specific patterns or events occur. With AI, unknown patterns and data sequences can be detected, processes automated, and business users notified.`} 
                bgColor={BgColor.PRIMARY}></YmTextBlock>
            </Col>
            <Col sm="12" md="6" className={'order-1 order-md-2 pb-4 pb-md-0 my-auto'}><div className="text-center p-4 w-100"><img  src={react_gif} alt="react" /></div></Col>
        </Row>
    </YmSection>
}

const FindUs = (): JSX.Element => {
    return <YmSection
        bgColor={BgColor.WHITE}
        fluid={'xl'}
        name={'Find us'}
        header={`Find us in the Stockholm region`}>
        <Row>
            <Col sm="12" md="6" className="px-4">
                <Row>
                    <Col>
                        <div className="mb-3">
                            <YmTextBlock
                                header={"Address"}
                                content={<div>Youmoni Lab<br /> Vretensborgsvägen 9 <br /> 126 30 Hägersten</div>}
                            ></YmTextBlock>
                        </div>
                    </Col>
                    <Col>
                        <div className="mb-3">
                            <YmTextBlock
                                header={"Delivery Address"}
                                content={<div>Youmoni Lab<br /> Vretensborgsvägen 9 <br /> 126 30 Hägersten</div>}
                            ></YmTextBlock>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <YmTextBlock
                                header={"HQ"}
                                content={<div>Jeti Services AB <br />Box 3035<br />18103 Lidingö</div>}
                            ></YmTextBlock>
                        </div>
                    </Col>
                    <Col>
                        <div className="mb-4">
                            <YmTextBlock
                                header={"Email"}
                                content={<a href="mailto:info@youmoni.com">info@youmoni.com</a>}
                            ></YmTextBlock>
                        </div>
                    </Col>
                </Row>


            </Col>
            <Col sm="12" md="6" className="px-4 order-1 order-md-2">
                <YmMap></YmMap>
            </Col>
        </Row>
    </YmSection>
}

const ContactUs = () => {
    return <YmSection
    bgColor={BgColor.PRIMARY}
    fluid={'xl'}
    name={'Contact'}
    header={`Don't hesitate to contact us on any matter related to digitization of physical processes, IoT technology, implementation projects with any type of connected product.`}>
        <Row>
            <Col sm="12" md="6" className="px-4">
            </Col>
            <Col sm="12" md="6" className="px-4 order-1 order-md-2">
            <ContactForm>   </ContactForm>
            </Col>
        </Row>
    </YmSection>
}

const About: React.FC<{ match: any }> = (props:any) => {
    const aboutImage = props.supportsWebp ? aboutWebp : aboutJpg;
    return (<div className="about">
        <YmHeader {...props} title="Shaping for a seamless future" media={<HeaderImage image={aboutImage}></HeaderImage>}></YmHeader>
        <AboutUs />
        <WhatWeDo />
        <Team />
        <FindUs></FindUs>
        <ContactUs></ContactUs>
    </div>
    );
}


export default About;    